@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: Sora;
  scroll-behavior: smooth;
}

.portfolio {
  height: 100vh;
  width: 100%;
  /* padding-left: 300px; */
  box-sizing: border-box;
  /* background: rgb(32, 32, 32); */
  color: white;
}

.topbar {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  background: rgba(58, 58, 58, 0.5);
  backdrop-filter: blur(5px);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 200ms;
}

.topbar .fa {
  display: none;
}

.mobile-tabs {
  display: none;
}

@media (max-width: 700px) {
  .topbar .nav-toggler {
    width: 100%;
  }

  .topbar .fa {
    display: block;
    text-align: right;
    font-size: 20px;
    padding: 10px;
    color: white;
  }

  .tabs {
    display: none;
  }

  .topbar {
    display: inline-block;
    height: fit-content;
  }

  .mobile-tabs {
    display: block;
  }

  .mobile-tabs a {
    display: block;
  }
}

.topbar .profile-pic {
  width: 100%;
  text-align: center;
  display: none;
}

.topbar img {
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  width: 200px;
  display: none;
}

.tabs {
  padding: 10px;
}

.tabs .tab-button {
  width: 100%;
  margin: 10px 0px;
  padding: 10px 20px;
  box-sizing: border-box;
  color: white;
  text-decoration: none;
  transition: ease-in-out 200ms;
}

.tabs .tab-button:hover {
  font-size: 20px;
}

.section-header {
  font-size: 60px;
  width: 100%;
  color: aqua;
  text-align: center;
  font-weight: bold;
  padding-bottom: 30px;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
  z-index: 1;
}

footer {
  padding: 10px;
  padding-block: 20px;
  background: rgb(31, 31, 31);
  width: 100%;
  text-align: center;
}

footer .counter {
  display: none;
}

/* preloader start */
.preloader {
  position: fixed;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  z-index: 100;
  background: black;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid rgb(15, 204, 157);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  border-color: rgb(0, 238, 255);
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* preloader end */

/* scrollbar */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1a161600;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.goku-bg{
  padding: 10px;
  overflow: hidden;
  border-radius: 10px;
  animation: movebg 1s linear infinite;
  transition: ease-in-out;
  background: linear-gradient(to right, black, rgba(80, 243, 255, 0.472), black);
}

@keyframes movebg {
  0% {
    background-position-x: -8rem;
  }
  100% {
    background-position: 0rem;
  }
}